const redirectRoutes = [
  {
    path: "/redirect",
    meta: {
      hasChild: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "redirect" */ "../views/redirect/RedirectBase.vue"
      ),
    children: [
      {
        path: "",
        name: "redirect/list",
        meta: {
          menuName: "Redirect",
          permission: "read.redirect",
          counterKey: "redirect",
        },
        component: () =>
          import(
            /* webpackChunkName: "redirect" */ "../views/redirect/RedirectList.vue"
          ),
      },
      {
        path: "/redirect/create",
        name: "redirect/create",
        meta: {
          permission: "create.redirect",
        },
        component: () =>
          import(
            /* webpackChunkName: "redirect" */ "../views/redirect/RedirectForm.vue"
          ),
      },
      {
        path: "/redirect/:uuid",
        name: "redirect/show",
        meta: {
          permission: "update.redirect",
        },
        component: () =>
          import(
            /* webpackChunkName: "redirect" */ "../views/redirect/RedirectForm.vue"
          ),
      },
    ],
  },
];

export default redirectRoutes;
