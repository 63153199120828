import { ref } from "vue";
import Swal from "sweetalert2";
import isObject from "lodash/isObject";
import { listDateRangeField } from "@/use/date";
import useClipboard from "vue-clipboard3";
import api from "@/services/api";
import moment from "moment";

export const toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
});

export const pageTitle = ref("Please wait..");
export const isLoading = ref(false);

export function exportResults({
  endpoint = null,
  params = null,
  fileName = null,
}) {
  isLoading.value = true;
  api
    .get(endpoint, {
      params: params,
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .finally(() => {
      isLoading.value = false;
    });
}

export function scrollToTitle() {
  document.getElementById("page-title").scrollIntoView();
}

export function parseBadgeCounter(val = null) {
  if (val === null || Number(val) <= 0) {
    return null;
  } else {
    return `${val}`;
  }
}

export function copyToClipboard(text, message = null, width = null) {
  const { toClipboard } = useClipboard();

  toClipboard(text).then(async () => {
    await toast.fire({
      icon: "success",
      html: message,
      timer: 3000,
      position: "top-right",
      width: width,
    });
  });
}

export function setPageTitle(title = null) {
  document.title = title;
  pageTitle.value = title;
}

export function convertToNumber(val) {
  return Number(val);
}

export const sortDir = [
  {
    id: "asc",
    name: "Ascending (A-Z)",
  },
  {
    id: "desc",
    name: "Descending (Z-A)",
  },
];

export function cleanQueryParams(data) {
  Object.keys(data).forEach((key) => {
    if (!data[key]) {
      delete data[key];
    } else if (isObject(data[key])) {
      if (listDateRangeField.includes(key)) {
        if (data[key]["start"] !== null && data[key]["end"] !== null) {
          data[key] = `${data[key]["start"]}-${data[key]["end"]}`;
        } else {
          delete data[key];
        }
      } else {
        data[key] = data[key]["id"];
      }
    }
  });

  return data;
}
export function convertObjectValuesToInteger(
  objectData = {},
  objectKeysToInteger = []
) {
  const keys = ["page", "limit", "total", ...objectKeysToInteger];

  Object.keys(objectData).forEach((key) => {
    if (keys.includes(key)) {
      objectData[key] = parseInt(objectData[key]);
    }
  });

  return objectData;
}

export function composeQueryString(query = {}) {
  let queryString = "";

  Object.keys(query).forEach((key, index) => {
    const prefix = index === 0 ? "?" : "&";

    queryString += `${prefix}${key}=${query[key]}`;
  });

  return queryString;
}

export function apiConfig(id = null) {
  const apiMethod = id ? "patch" : "post";

  return {
    apiMethod,
  };
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function checkTimeLimit(date) {
  return relativeTimeInHour(date) >= 24;
}

export function relativeTimeInHour(date) {
  const start = moment(new Date(date));
  const end = moment(new Date());
  return end.diff(start, "hours");
}

function convertMilliseconds(milliseconds) {
  const seconds = milliseconds / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  const daysInt = Math.floor(days);
  const hoursInt = Math.floor(hours) % 24;
  const minutesInt = Math.floor(minutes) % 60;
  const secondsInt = Math.floor(seconds) % 60;

  return {
    days: daysInt,
    hours: hoursInt,
    minutes: minutesInt,
    seconds: secondsInt,
  };
}

export function relativeTimeFormat(targetDate, startDate = null) {
  // Your target date (e.g., from a database or user input)

  // Calculate relative time from now to the target date
  const now = startDate ? moment(startDate) : moment();
  const futureDate = moment(targetDate); // Your target date

  if (startDate) {
    console.log(now);
  }

  const duration = moment.duration(now.diff(futureDate));

  const durationInMs = duration.asMilliseconds();

  const data = convertMilliseconds(durationInMs);

  const days = data.days;
  const hours = data.hours;
  const minutes = data.minutes;
  const seconds = data.seconds;

  // Create a human-readable relative time string
  let relativeTime = "";

  if (days > 0) {
    relativeTime += `${days}d`;
  }

  if (hours > 0) {
    relativeTime += ` ${hours}h`;
  }

  if (minutes > 0) {
    relativeTime += ` ${minutes}m`;
  } else {
    relativeTime += ` ${seconds}s`;
  }

  // Output the relative time
  return relativeTime;
}
