const phishingRoutes = [
  {
    path: "/phishing/report/removal-time",
    name: "phishing/report/removal-time/list",
    meta: {
      menuName: "Report: Removal Time",
      permission: "phishing.report.removal_time",
    },
    component: () =>
      import(
        /* webpackChunkName: "call" */ "../views/phishing-report/PhishingReportRemovalTime.vue"
      ),
  },
  {
    path: "/phishing/report/summary",
    name: "phishing/report/summary/list",
    meta: {
      menuName: "Report: Summary",
      permission: "phishing.report.summary",
    },
    component: () =>
      import(
        /* webpackChunkName: "call" */ "../views/phishing-report/PhishingReportSummary.vue"
      ),
  },
  {
    path: "/phishing-brand-create",
    name: "phishing-brand-create",
    meta: {
      menuName: "Create Store",
      permission: "phishing.brand.create",
    },
    component: () =>
      import(
        /* webpackChunkName: "phishing" */ "../views/phishing-brand/PhishingBrandForm.vue"
      ),
  },
  {
    path: "/phishing/brand",
    meta: {
      hasChild: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "phishing" */ "../views/phishing-brand/PhishingBrandBase.vue"
      ),
    children: [
      {
        path: "",
        name: "phishing/brand/list",
        meta: {
          menuName: "Database Store",
          permission: "phishing.brand.read",
        },
        component: () =>
          import(
            /* webpackChunkName: "phishing" */ "../views/phishing-brand/PhishingBrandList.vue"
          ),
      },
      {
        path: "/phishing/brand/create",
        name: "phishing/brand/create",
        meta: {
          permission: "phishing.brand.create",
        },
        component: () =>
          import(
            /* webpackChunkName: "phishing" */ "../views/phishing-brand/PhishingBrandForm.vue"
          ),
      },
      {
        path: "/phishing/brand/:uuid",
        name: "phishing/brand/show",
        meta: {
          permission: "phishing.brand.update",
        },
        component: () =>
          import(
            /* webpackChunkName: "phishing" */ "../views/phishing-brand/PhishingBrandForm.vue"
          ),
      },
    ],
  },
  {
    path: "/phishing/engine",
    meta: {
      hasChild: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "phishing" */ "../views/phishing-engine/PhishingEngineBase.vue"
      ),
    children: [
      {
        path: "",
        name: "phishing/engine/list",
        meta: {
          menuName: "Database Engine",
          permission: "phishing.engine.read",
        },
        component: () =>
          import(
            /* webpackChunkName: "phishing" */ "../views/phishing-engine/PhishingEngineList.vue"
          ),
      },
      {
        path: "/phishing/engine/create",
        name: "phishing/engine/create",
        meta: {
          permission: "phishing.engine.create",
        },
        component: () =>
          import(
            /* webpackChunkName: "phishing" */ "../views/phishing-engine/PhishingEngineForm.vue"
          ),
      },
      {
        path: "/phishing/engine/:uuid",
        name: "phishing/engine/show",
        meta: {
          permission: "phishing.engine.update",
        },
        component: () =>
          import(
            /* webpackChunkName: "phishing" */ "../views/phishing-engine/PhishingEngineForm.vue"
          ),
      },
    ],
  },
  {
    path: "/phishing",
    meta: {
      hasChild: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "phishing" */ "../views/phishing/PhishingBase.vue"
      ),
    children: [
      {
        path: "",
        name: "phishing/list",
        meta: {
          menuName: "Ticket",
          permission: "phishing.ticket.read",
        },
        component: () =>
          import(
            /* webpackChunkName: "phishing" */ "../views/phishing/PhishingList.vue"
          ),
      },
      {
        path: "/phishing/create",
        name: "phishing/create",
        meta: {
          permission: "phishing.ticket.create",
        },
        component: () =>
          import(
            /* webpackChunkName: "phishing" */ "../views/phishing/PhishingForm.vue"
          ),
      },
      {
        path: "/phishing/:uuid",
        name: "phishing/show",
        meta: {
          permission: "phishing.ticket.update",
        },
        component: () =>
          import(
            /* webpackChunkName: "phishing" */ "../views/phishing/PhishingForm.vue"
          ),
      },
    ],
  },
  {
    path: "/phishing/link",
    meta: {
      hasChild: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "phishing" */ "../views/phishing-link/PhishingLinkBase.vue"
      ),
    children: [
      {
        path: "",
        name: "phishing/link/list",
        meta: {
          menuName: "Phishing Links",
          permission: "phishing.link.read",
        },
        component: () =>
          import(
            /* webpackChunkName: "phishing" */ "../views/phishing-link/PhishingLinkList.vue"
          ),
      },
    ],
  },
];

export default phishingRoutes;
