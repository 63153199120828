import api from "@/services/api.js";

export default {
  namespaced: true,
  state: {
    accessToken: null,
    permission: null,
    username: null,
    role: null,
    group: null,
    groupType: null,
  },
  mutations: {
    saveAccessToken(state, token) {
      state.accessToken = token;
    },
    savePermission(state, permission) {
      state.permission = permission;
    },
    saveUserInfo(state, { group, username, role, group_type }) {
      state.group = group;
      state.username = username;
      state.role = role;
      state.groupType = group_type;
    },
    destroyUserInfo(state) {
      state.group = null;
      state.username = null;
      state.role = null;
      state.groupType = null;
    },
    destroyPermission(state) {
      state.permission = null;
    },
    destroyToken(state) {
      state.accessToken = null;
    },
  },
  actions: {
    logout(context) {
      return new Promise((resolve, reject) => {
        api
          .post("auth/logout")
          .then((res) => {
            context.commit("destroyPermission");
            context.commit("destroyToken");
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    },
  },
  getters: {
    allPermission(state) {
      return state.permission;
    },
    groupType(state) {
      return state.groupType;
    },
    isLoggedIn(state) {
      return state.accessToken !== null;
    },
    userCan: (state) => (permission) => {
      return state.permission !== null
        ? Object.values(state.permission).includes(permission)
        : false;
    },
    accessToken(state) {
      return state.accessToken;
    },
    userInfo: (state) => (type) => {
      return state[type];
    },
  },
};
