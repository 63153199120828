import { ref } from "vue";
import moment from "moment";

export const listDateRangeField = [
  "last_deposit_date",
  "last_call_date",
  "call_date",
  "achievement_date",
  "date",
  "deposit_date",
  "request_date",
  "report_date",
];

export const vCalendarToday = {
  start: moment(new Date()).format("YYYYMMDD"),
  end: moment(new Date()).format("YYYYMMDD"),
};

export const currentDate = new Date();
export const currentDateParsed = moment(currentDate).format("DD MMM YYYY");
export const dateClickCounter = ref(0);

export function resetDateClickCounter() {
  dateClickCounter.value = 0;
}

export function parseInputValue(inputValue) {
  return inputValue.start && inputValue.end
    ? inputValue.start + " - " + inputValue.end
    : "";
}
