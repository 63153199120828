const acquisitionRoutes = [
  {
    path: "/cold-data/import",
    meta: {
      hasChild: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "cold-data-import" */ "../views/cold-data-import/ColdDataImportBase.vue"
      ),
    children: [
      {
        path: "",
        name: "cold-data/import/list",
        meta: {
          menuName: "Import Cold Call Data",
          permission: "read.cold_data_import",
        },
        component: () =>
          import(
            /* webpackChunkName: "cold-data-import" */ "../views/cold-data-import/ColdDataImportList.vue"
          ),
      },
      {
        path: "/cold-data/import/upload",
        name: "cold-data/import/upload",
        meta: {
          permission: "upload.cold_data_import",
        },
        component: () =>
          import(
            /* webpackChunkName: "cold-data-import" */ "../views/cold-data-import/ColdDataImportForm.vue"
          ),
      },
    ],
  },
  {
    path: "/cold-data",
    name: "cold-data/list",
    meta: {
      menuName: "Cold Call Data",
      permission: "read.cold_data",
    },
    component: () =>
      import(
        /* webpackChunkName: "cold-data" */ "../views/cold-data/ColdDataList.vue"
      ),
  },
  {
    path: "/cold-data/:uuid",
    name: "cold-data/show",
    meta: {
      permission: "read.cold_data",
    },
    component: () =>
      import(
        /* webpackChunkName: "cold-data" */ "../views/cold-data/ColdDataForm.vue"
      ),
  },
  {
    path: "/cold-call",
    name: "cold-call",
    meta: {
      menuName: "New Call",
      permission: "get.cold_call",
      counterKey: "cold_call",
    },
    component: () =>
      import(
        /* webpackChunkName: "cold-call" */ "../views/cold-data/ColdDataCall.vue"
      ),
  },
  {
    path: "/cold-callback",
    name: "cold-callback",
    meta: {
      menuName: "Callback",
      permission: "get.cold_call",
      counterKey: "cold_callback",
    },
    component: () =>
      import(
        /* webpackChunkName: "cold-call" */ "../views/cold-data/ColdDataCall.vue"
      ),
  },
  {
    path: "/cold-data/call-summary",
    name: "cold-data/call-summary",
    meta: {
      menuName: "Call Summary",
      permission: "call_summary.cold_data",
    },
    component: () =>
      import(
        /* webpackChunkName: "cold-data-log" */ "../views/cold-data/ColdDataCallSummary.vue"
      ),
  },
  {
    path: "/cold-data/call-log",
    name: "cold-data/call-log",
    meta: {
      menuName: "Call Log",
      permission: "call_log.cold_data",
    },
    component: () =>
      import(
        /* webpackChunkName: "cold-data-log" */ "../views/cold-data/ColdDataCallLog.vue"
      ),
  },
  {
    path: "/cold-call/log",
    name: "cold-call/log",
    meta: {
      menuName: "Call Log",
      permission: "get.cold_call",
      counterKey: "cold_call_log",
    },
    component: () =>
      import(
        /* webpackChunkName: "cold-data-log" */ "../views/cold-data/ColdDataLog.vue"
      ),
  },
  {
    path: "/cold-call/request/:uuid",
    name: "cold-call/request",
    meta: {
      permission: "get.cold_call",
    },
    component: () =>
      import(
        /* webpackChunkName: "cold-data-log" */ "../views/cold-data/ColdDataRequest.vue"
      ),
  },
  {
    path: "/cold-data/achievement-log",
    name: "cold-data/achievement-log",
    meta: {
      menuName: "Achievement Log",
      permission: "achievement_log.cold_data",
    },
    component: () =>
      import(
        /* webpackChunkName: "cold-data-log" */ "../views/cold-data/ColdDataAchievementLog.vue"
      ),
  },
  {
    path: "/cold-followup",
    name: "cold-followup",
    meta: {
      menuName: "Follow Up",
      permission: "get.cold_call",
      counterKey: "cold_followup",
    },
    component: () =>
      import(
        /* webpackChunkName: "cold-data-log" */ "../views/cold-data/ColdDataFollowup.vue"
      ),
  },
];

export default acquisitionRoutes;
