import axios from "axios";
import store from "@/store/index.js";
import Swal from "sweetalert2";
import router from "@/router";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers["Accept"] = "application/json";
axios.defaults.headers["Content-Type"] = "application/json";

axios.interceptors.request.use(async (config) => {
  if (store.getters["auth/accessToken"]) {
    config.headers["Authorization"] =
      "Bearer " + store.getters["auth/accessToken"];
  }

  return config;
});

axios.interceptors.response.use(
  (res) => {
    const {
      data: { version },
    } = res;

    if (res.config.url) {
      if (!res.config.url.startsWith(process.env.VUE_APP_QR_URL)) {
        store.commit("versioning/saveServerVersion", version);
      }
    } else {
      store.commit("versioning/saveServerVersion", version);
    }

    return Promise.resolve(res);
  },
  async (err) => {
    if (err.response) {
      const {
        data: { code, message },
      } = err.response;

      if (code === 9205 || code === 9206) {
        store.commit("auth/destroyToken");
        store.commit("auth/destroyPermission");
        store.commit("auth/destroyUserInfo");

        router.push({ name: "auth/login" }).then(async () => {
          await Swal.fire({
            title: "Whoops!",
            html: message,
            icon: "warning",
          });
        });
      } else if (code === 9108) {
        if (!err.response.config.url.startsWith(process.env.VUE_APP_QR_URL)) {
          await Swal.fire({
            title: "Whoops!",
            text: message,
            icon: "info",
          });
        }
      } else if (code === 9109) {
        await Swal.fire({
          title: "Whoops!",
          html: message,
          icon: "warning",
          showConfirmButton: false,
          allowOutsideClick: false,
        });
      }
    } else {
      await Swal.fire({
        icon: "error",
        title: err.message,
        text: "Please try again or contact IT support if the problem persists.",
        showCloseButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
      });
    }

    return Promise.reject(err);
  }
);

export default axios;
