const appVersion = process.env.VUE_APP_VERSION;
const appEnvironment = process.env.VUE_APP_ENVIRONMENT;

export default {
  namespaced: true,
  state: {
    serverVersion: null,
  },
  mutations: {
    saveServerVersion(state, serverVersion) {
      state.serverVersion = serverVersion;
    },
  },
  getters: {
    invalidVersion(state) {
      if (state.serverVersion) {
        try {
          const appVersionFromServer =
            state.serverVersion.match(/\((.*)\)/i)[1];

          return appVersion !== appVersionFromServer;
        } catch (e) {
          return true;
        }
      } else {
        return false;
      }
    },
    currentServerVersion(state) {
      return state.serverVersion
        ? state.serverVersion.replace(/ *\([^)]*\) */g, "")
        : null;
    },
    currentAppVersion() {
      return appVersion;
    },
    isProduction() {
      return appEnvironment === "production";
    },
  },
};
